import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './ui/table';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from './ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Alert, AlertDescription } from './ui/alert';
import { PDFDownloadLink } from '@react-pdf/renderer';
import AssessmentPDF from './AssessmentPDF';
import { FileDown, Loader } from 'lucide-react';
import { Checkbox } from './ui/checkbox';
import { loadQuestions } from '../utils/questionLoader';
import { Textarea } from './ui/textarea';
import EditPrompt from './EditPrompt';
import ReviewAPIResponse from './ReviewAPIResponse';
import ViewReport from './ViewReport';
import FinalReportEditor from './FinalReportEditor';
import ThreatIntelTab from './ThreatIntelTab';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

const AdminPanel = () => {
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [activityLogs, setActivityLogs] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [error, setError] = useState('');
  const pageSize = 10;
  const [promptInput, setPromptInput] = useState('');
  const [showPromptDialog, setShowPromptDialog] = useState(false);
  const [workflowStep, setWorkflowStep] = useState('selectAssessment'); // Changed from editPrompt
  const [adminSelectedAssessment, setAdminSelectedAssessment] = useState(null); //for admin workflow
  const [prompt, setPrompt] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [report, setReport] = useState('');
  const [currentAssessmentId, setCurrentAssessmentId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [language, setLanguage] = useState('en'); // Default to English
  const [isAdminOverride, setIsAdminOverride] = useState(false);
  const [remainingApiCalls, setRemainingApiCalls] = useState(10);
  const [newUserRole, setNewUserRole] = useState('user');
  const [showReportAndPromptDialog, setShowReportAndPromptDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [assessmentToDelete, setAssessmentToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const categories = [
          'business', 'general', 'advanced',
          'size-small', 'size-medium', 'size-large',
          'sector-finance', 'sector-healthcare', 'sector-technology', 'sector-retail', 'sector-manufacturing', 'sector-education', 'sector-government', 'sector-transportation', 'sector-energy', 'sector-telecom',
          'maturity-basic', 'maturity-intermediate', 'maturity-advanced',
          'framework-preliminary', 'framework-basiccompliance', 'framework-nist171', 'framework-iso27001', 'framework-cis', 'framework-pcidss', 'framework-socii', 'framework-icsot'
        ];
        const loadedQuestions = await loadQuestions(categories);
        console.log("Loaded questions:", loadedQuestions);
        setQuestions(loadedQuestions);
      } catch (error) {
        console.error('Error loading questions:', error);
      }
    };

    if (user && user.isAdmin) {
      fetchQuestions();
    }
  }, [user]);

  const fetchUsers = useCallback(async (page) => {
    try {
      const token = localStorage.getItem('token');
      const url = `${API_BASE_URL}/api/admin/users?page=${page}&pageSize=${pageSize}&filter=${filter}&sortBy=${sortBy}`;
      console.log('Fetching users from:', url);
      const response = await fetch(url, {
        headers: { 'x-auth-token': token }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Users data:', data);
      setUsers(data.users);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(`Failed to fetch users: ${error.message}`);
    }
  }, [filter, sortBy]); 

  const fetchAssessments = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const url = `${API_BASE_URL}/api/assessments/all`;
      console.log('Fetching assessments from:', url);
      const response = await fetch(url, {
        headers: { 'x-auth-token': token }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Assessments data:', data);
      setAssessments(data);
    } catch (error) {
      console.error('Error fetching assessments:', error);
      setError(`Failed to fetch assessments: ${error.message}`);
    }
  }, []);

  const fetchActivityLogs = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const url = `${API_BASE_URL}/api/admin/activity-logs`;
      console.log('Fetching activity logs from:', url);
      const response = await fetch(url, {
        headers: { 'x-auth-token': token }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Activity logs data:', data);
      setActivityLogs(data);
    } catch (error) {
      console.error('Error fetching activity logs:', error);
      setError(`Failed to fetch activity logs: ${error.message}`);
    }
  }, []);

  useEffect(() => {
    console.log('AdminPanel useEffect triggered');
    console.log('User:', user);
    console.log('Is admin:', user?.isAdmin);
    if (user && user.isAdmin) {
      console.log('Fetching admin data');
      fetchUsers(currentPage);
      fetchAssessments();
      fetchActivityLogs();
    } else {
      console.log('Not fetching admin data - user is not admin or not logged in');
    }
  }, [user, currentPage, fetchUsers, fetchAssessments, fetchActivityLogs]);

const handleUpdateUser = async (e) => {
  e.preventDefault();
  setError('');

  if (!selectedUser.name.trim() || !selectedUser.email.trim()) {
    setError('Name and email are required');
    return;
  }
  
  if (!/\S+@\S+\.\S+/.test(selectedUser.email)) {
    setError('Invalid email format');
    return;
  }

  try {
    const token = localStorage.getItem('token');
    if (!selectedUser || !selectedUser._id) {
      setError('Invalid user selected');
      return;
    }
    const response = await fetch(`${API_BASE_URL}/api/admin/users/${selectedUser._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      },
      body: JSON.stringify({
        name: selectedUser.name,
        email: selectedUser.email,
        role: selectedUser.role
      })
    });

    if (response.ok) {
      const updatedUser = await response.json();
      setUsers(users.map(u => u._id === updatedUser._id ? updatedUser : u));
      setSelectedUser(null);
    } else {
      setError('Failed to update user');
    }
  } catch (error) {
    console.error('Error updating user:', error);
    setError('An error occurred while updating the user');
  }
};

  const handleRegisterUser = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/auth/admin/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
        body: JSON.stringify({
          name: e.target.name.value,
          email: e.target.email.value,
          password: e.target.password.value,
          role: newUserRole // Use the state value here
        })
      });

      const data = await response.json();

      if (response.ok) {
        setUsers([...users, data.user]);
        e.target.reset();
        setNewUserRole('user'); // Reset role after successful registration
      } else {
        if (data.errors && Array.isArray(data.errors)) {
          setError(data.errors.map(err => err.msg).join(', '));
        } else {
          setError(data.message || 'Failed to register user');
        }
      }
    } catch (error) {
      console.error('Error registering user:', error);
      setError('An error occurred while registering the user');
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/admin/users/${userId}`, {
        method: 'DELETE',
        headers: { 'x-auth-token': token }
      });

      if (response.ok) {
        setUsers(users.filter(u => u._id !== userId));
      } else {
        console.error('Failed to delete user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleGenerateReport = async (assessmentId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/assessments/${assessmentId}/generate-report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        }
      });

      if (!response.ok) {
        throw new Error('Failed to generate report');
      }

      // Refresh the assessments list
      fetchAssessments();
    } catch (error) {
      console.error('Error generating report:', error);
      setError('Failed to generate report. Please try again.');
    }
  };

const viewFullReportAndPrompt = async (assessmentId) => {
  console.log("viewFullReportAndPrompt called with assessmentId:", assessmentId);
  try {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}/api/assessments/${assessmentId}/report`;
    const response = await fetch(url, {
      headers: { 'x-auth-token': token }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reportData = await response.json();
    
    // Fetch all questions, including framework and advanced
    const allCategories = [
      'business', 'general', 'advanced',
      `size-${(reportData.orgSize || '').toLowerCase()}`,
      `sector-${(reportData.orgSector || '').toLowerCase()}`,
      ...(reportData.maturity ? [`maturity-${reportData.maturity.toLowerCase()}`] : []),
      ...(reportData.selectedFramework ? [`framework-${reportData.selectedFramework.toLowerCase()}`] : [])
    ].filter(Boolean);
    
    console.log("Categories to load:", allCategories);
    const allQuestions = await loadQuestions(allCategories);
    console.log("Loaded questions:", allQuestions);

    console.log("All loaded questions:", allQuestions);
    console.log("Assessment answers:", reportData.answers);
    console.log("Assessment notes:", reportData.assessmentNotes);

    setSelectedAssessment({...reportData, questions: allQuestions});

    // Generate the prompt
    const promptResponse = await fetch(`${API_BASE_URL}/api/assessments/${assessmentId}/review`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      },
      body: JSON.stringify({ 
        action: 'get_prompt',
        assessment: reportData,
        questions: allQuestions
      })
    });

    if (!promptResponse.ok) {
      const errorData = await promptResponse.json();
      throw new Error('Failed to get prompt');
    }

    const promptData = await promptResponse.json();
    setPromptInput(promptData.prompt);
    setCurrentAssessmentId(assessmentId);
    setShowReportAndPromptDialog(true);
    console.log("showReportAndPromptDialog set to true");
    setWorkflowStep('editPrompt');

  } catch (error) {
    console.error('Error fetching full report and prompt:', error);
    setError(`Failed to fetch full report and prompt: ${error.message}`);
  }
};

const handlePDFDownload = async (assessmentId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/api/assessments/${assessmentId}/pdf`, {
      headers: {
        'x-auth-token': token
      }
    });

    if (!response.ok) {
      throw new Error('Failed to generate PDF');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `assessment_report_${assessmentId}.pdf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading PDF:', error);
    setError('Failed to download PDF. Please try again.');
  }
};

const fetchFullAssessment = async (assessmentId) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_BASE_URL}/api/assessments/${assessmentId}/report`, {
    headers: { 'x-auth-token': token }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch full assessment');
  }
  return await response.json();
};

  const handleDeleteAssessment = (assessment) => {
    setAssessmentToDelete(assessment);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteAssessment = async () => {
    if (assessmentToDelete) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/assessments/${assessmentToDelete._id}`, {
          method: 'DELETE',
          headers: { 'x-auth-token': token }
        });

        if (response.ok) {
          setAssessments(assessments.filter(assessment => assessment._id !== assessmentToDelete._id));
          setShowDeleteConfirmation(false);
          setAssessmentToDelete(null);
        } else {
          throw new Error('Failed to delete assessment');
        }
      } catch (error) {
        setError('Error deleting assessment: ' + error.message);
      }
    }
  };

  const deleteAssessment = async (assessmentId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/assessments/${assessmentId}`, {
        method: 'DELETE',
        headers: { 'x-auth-token': token }
      });

      if (response.ok) {
        setAssessments(assessments.filter(assessment => assessment._id !== assessmentId));
      } else {
        throw new Error('Failed to delete assessment');
      }
    } catch (error) {
      setError('Error deleting assessment: ' + error.message);
    }
  };

  const handleEditPrompt = (editedPrompt) => {
    setPrompt(editedPrompt);
    setWorkflowStep('releaseToAPI');
  };

  const handleSelectAssessment = async (assessment) => {
    setSelectedAssessment(assessment);
    setWorkflowStep('editPrompt');
    
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/assessments/${assessment._id}/report`, {
        headers: { 'x-auth-token': token }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reportData = await response.json();
      
      const allCategories = [
        'business', 'general', 'advanced',
        `size-${reportData.orgSize.toLowerCase()}`,
        `sector-${reportData.orgSector.toLowerCase()}`,
        `maturity-${reportData.maturity.toLowerCase()}`,
        // Special handling for preliminary framework
        reportData.selectedFramework === 'framework-preliminary' 
          ? 'framework-preliminary'
          : reportData.selectedFramework.toLowerCase().startsWith('framework-')
            ? reportData.selectedFramework.toLowerCase()
            : `framework-${reportData.selectedFramework.toLowerCase()}`
      ].filter(Boolean);
      
      const allQuestions = await loadQuestions(allCategories);
      setQuestions(allQuestions);

      const promptResponse = await fetch(`${API_BASE_URL}/api/assessments/${assessment._id}/review`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
        body: JSON.stringify({ 
          action: 'get_prompt',
          assessment: reportData,
          questions: allQuestions
        })
      });

      if (!promptResponse.ok) {
        throw new Error('Failed to get prompt');
      }

      const promptData = await promptResponse.json();
      setPromptInput(promptData.prompt);

    } catch (error) {
      console.error('Error fetching assessment details:', error);
      setError(`Failed to fetch assessment details: ${error.message}`);
    }
  };

  const handleReleaseToAPI = async (assessmentId) => {
    if (!assessmentId) {
      setError('Invalid assessment ID. Please try again.');
      return;
    }
        
    if (typeof assessmentId !== 'string') {
      console.error('Invalid assessmentId:', assessmentId);
      setError('Invalid assessment ID');
      return;
    }
    
    if (remainingApiCalls <= 0 && !isAdminOverride) {
      setError('API call limit reached. Please use admin override or wait for the limit to reset.');
      return;
    }
    
    try {
      const token = localStorage.getItem('token');
    
      if (!selectedAssessment) {
        throw new Error('No assessment selected');
      }
      
      const response = await fetch(`${API_BASE_URL}/api/assessments/${assessmentId}/review`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
        body: JSON.stringify({
          action: 'release_to_chatgpt',
          assessment: selectedAssessment,
          questions: selectedAssessment.questions,
          customPrompt: promptInput,
          isAdminOverride: isAdminOverride
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to release assessment to ChatGPT API');
      }
    
      const data = await response.json();
      setRemainingApiCalls(data.remainingCalls);
  
      // Fetch the updated assessment immediately
      const updatedAssessmentResponse = await fetch(`${API_BASE_URL}/api/assessments/${assessmentId}/report`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
    
      if (updatedAssessmentResponse.ok) {
        const updatedAssessment = await updatedAssessmentResponse.json();
        setSelectedAssessment(updatedAssessment);
      }
        
      fetchAssessments(); // Refresh the assessments list
      setShowReportAndPromptDialog(false); // Close the dialog
      setWorkflowStep('reviewResponse'); // Move to the next step
      setError(''); // Clear any previous errors
    } catch (error) {
      console.error('Error releasing assessment to API:', error);
      setError(error.message);
    }
  };

  const handleResubmit = async (feedback) => {
    setIsLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/assessments/${selectedAssessment._id}/resubmit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({ feedback }),
      });

      if (response.ok) {
        const data = await response.json();
        setApiResponse(data.response);
      } else {
        throw new Error('Failed to resubmit');
      }
    } catch (error) {
      setError('Error resubmitting: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewReport = () => {
    setReport(apiResponse);
    setWorkflowStep('viewReport');
  };

  const handleEditReport = (editedReport) => {
    setReport(editedReport);
    setWorkflowStep('finalReport');
  };

  const handleFinalizeReport = async (finalReport) => {
    setIsLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/assessments/${selectedAssessment._id}/finalize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({ report: finalReport }),
      });

      if (response.ok) {
        setWorkflowStep('downloadPDF');
      } else {
        throw new Error('Failed to finalize report');
      }
    } catch (error) {
      setError('Error finalizing report: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdminWorkflow = async (assessmentId) => {
    try {
      await viewFullReportAndPrompt(assessmentId);
      setAdminSelectedAssessment(selectedAssessment);
      setWorkflowStep('editPrompt');
    } catch (error) {
      console.error('Error in admin workflow:', error);
      setError(`Error in admin workflow: ${error.message}`);
    }
  };

  const renderWorkflowStep = () => {
    switch (workflowStep) {
      case 'selectAssessment':
        return (
          <div>
            <h3 className="text-lg font-semibold mb-4">Select an Assessment</h3>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>User</TableHead>
                  <TableHead>Organization Size</TableHead>
                  <TableHead>Organization Sector</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {assessments.map(assessment => (
                  <TableRow key={assessment._id}>
                    <TableCell>{assessment.user?.name || 'N/A'}</TableCell>
                    <TableCell>{assessment.orgSize}</TableCell>
                    <TableCell>{assessment.orgSector}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleAdminWorkflow(assessment._id)}>
                        Start Workflow
                      </Button>
                      <Button onClick={() => handleDeleteAssessment(assessment)} variant="destructive" className="ml-2">
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        );
      case 'editPrompt':
        return (
          <div>
            <h3 className="text-lg font-semibold mb-4">Edit Prompt</h3>
            <Textarea
              value={promptInput}
              onChange={(e) => setPromptInput(e.target.value)}
              rows={10}
              className="w-full mb-4"
            />
            <div className="flex items-center mb-4">
              <Checkbox
                id="adminOverride"
                checked={isAdminOverride}
                onCheckedChange={setIsAdminOverride}
              />
              <Label htmlFor="adminOverride" className="ml-2">
                Admin Override
              </Label>
            </div>
            <Button onClick={() => setWorkflowStep('releaseToAPI')}>
              Continue to Release
            </Button>
          </div>
        );
      case 'releaseToAPI':
        return (
          <div>
            <h3 className="text-lg font-semibold mb-4">Release to API</h3>
            <p className="mb-4">Are you sure you want to release this prompt to the API?</p>
            <Button 
              onClick={() => handleReleaseToAPI(selectedAssessment._id)} 
              disabled={remainingApiCalls <= 0 && !isAdminOverride}
            >
              Release to ChatGPT API ({remainingApiCalls} calls remaining)
            </Button>
          </div>
        );
      case 'reviewResponse':
        return (
          <div>
            <h3 className="text-lg font-semibold mb-4">Review API Response</h3>
            <Textarea
              value={selectedAssessment.apiResponse || ''}
              readOnly
              rows={10}
              className="w-full mb-4"
            />
            <Button onClick={() => setWorkflowStep('editPrompt')}>
              Edit Prompt
            </Button>
            <Button onClick={() => setWorkflowStep('viewReport')} className="ml-2">
              Continue to View Report
            </Button>
          </div>
        );
      case 'viewReport':
        return (
          <div>
            <h3 className="text-lg font-semibold mb-4">View Report</h3>
            <div className="mb-4">
              <h4 className="font-semibold">Analysis Result:</h4>
              <Textarea
                value={selectedAssessment.analysisResult || ''}
                onChange={(e) => setSelectedAssessment({...selectedAssessment, analysisResult: e.target.value})}
                rows={5}
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <h4 className="font-semibold">Maturity Score:</h4>
              <Input
                value={selectedAssessment.maturityScore || ''}
                onChange={(e) => setSelectedAssessment({...selectedAssessment, maturityScore: e.target.value})}
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <h4 className="font-semibold">Action Plan:</h4>
              <Textarea
                value={selectedAssessment.actionPlan || ''}
                onChange={(e) => setSelectedAssessment({...selectedAssessment, actionPlan: e.target.value})}
                rows={5}
                className="w-full"
              />
            </div>
            <Button onClick={() => setWorkflowStep('downloadPDF')}>
              Continue to Download PDF
            </Button>
          </div>
        );
      case 'finalReport':
        return (
          <FinalReportEditor
            initialReport={selectedAssessment}
            onSave={handleEditReport}
            onNext={() => setWorkflowStep('downloadPDF')}
          />
        );
      case 'downloadPDF':
        return (
          <div>
            <h3 className="text-lg font-semibold mb-4">Download PDF</h3>
            <Button onClick={() => handlePDFDownload(selectedAssessment._id)} className="ml-2">
              <FileDown className="mr-2 h-4 w-4" />
              Download PDF
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

const renderUsersTab = () => (
  <Card>
    <CardHeader>
      <CardTitle>Users</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="mb-4 flex space-x-4">
        <Input 
          placeholder="Filter users..." 
          value={filter} 
          onChange={(e) => setFilter(e.target.value)} 
        />
        <Select value={sortBy} onValueChange={setSortBy}>
          <SelectTrigger>
            <SelectValue placeholder="Sort by..." />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="name">Name</SelectItem>
            <SelectItem value="email">Email</SelectItem>
            <SelectItem value="customerIdentifier">Customer ID</SelectItem>
            <SelectItem value="createdAt">Date Created</SelectItem>
          </SelectContent>
        </Select>
      </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Customer ID</TableHead>
              <TableHead>Role</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users.map(user => (
              <TableRow key={user._id}>
                <TableCell>{user?.name || 'N/A'}</TableCell>
                <TableCell>{user?.email || 'N/A'}</TableCell>
                <TableCell>{user?.customerIdentifier || 'N/A'}</TableCell>
                <TableCell>{user?.role || 'user'}</TableCell>
                <TableCell>
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button variant="outline" onClick={() => setSelectedUser(user)}>Edit</Button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Edit User</DialogTitle>
                      </DialogHeader>
                      <form onSubmit={handleUpdateUser} className="space-y-4">
                        <div>
                          <Label htmlFor="name">Name</Label>
                          <Input
                            id="name"
                            value={selectedUser?.name || ''}
                            onChange={(e) => setSelectedUser({...selectedUser, name: e.target.value})}
                          />
                        </div>
                        <div>
                          <Label htmlFor="email">Email</Label>
                          <Input
                            id="email"
                            value={selectedUser?.email || ''}
                            onChange={(e) => setSelectedUser({...selectedUser, email: e.target.value})}
                          />
                        </div>
                        <div>
                          <Label htmlFor="role">Role</Label>
                          <Select
                            value={selectedUser?.role || 'user'}
                            onValueChange={(value) => setSelectedUser({...selectedUser, role: value})}
                          >
                            <SelectTrigger id="role">
                              <SelectValue placeholder="Select role" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="user">User</SelectItem>
                              <SelectItem value="admin">Admin</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        {error && <p className="text-red-500">{error}</p>}
                        <Button type="submit">Update User</Button>
                      </form>
                    </DialogContent>
                  </Dialog>
                  <Button variant="destructive" onClick={() => handleDeleteUser(user._id)} className="ml-2">Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-4">Register New User</h3>
          <form onSubmit={handleRegisterUser} className="space-y-4">
            <div>
              <Label htmlFor="name">Name</Label>
              <Input id="name" name="name" required />
            </div>
            <div>
              <Label htmlFor="email">Email</Label>
              <Input id="email" name="email" type="email" required />
            </div>
            <div>
              <Label htmlFor="password">Password</Label>
              <Input id="password" name="password" type="password" required />
            </div>
            <div>
              <Label htmlFor="role">Role</Label>
              <Select value={newUserRole} onValueChange={setNewUserRole}>
                <SelectTrigger id="role">
                  <SelectValue placeholder="Select role" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="user">User</SelectItem>
                  <SelectItem value="admin">Admin</SelectItem>
                </SelectContent>
              </Select>
            </div>
            {error && <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>}
            <Button type="submit">Register User</Button>
          </form>
        </div>
      </CardContent>
    </Card>
  );

const renderAssessmentsTab = () => (
  <Card>
    <CardHeader>
      <CardTitle>Assessments</CardTitle>
    </CardHeader>
    <CardContent>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>User</TableHead>
            <TableHead>Organization Size</TableHead>
            <TableHead>Organization Sector</TableHead>
            <TableHead>Completion Date</TableHead>
            <TableHead>Report Requested</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {assessments.map(assessment => (
            <TableRow key={assessment._id}>
              <TableCell>{assessment.user?.name || 'N/A'}</TableCell>
              <TableCell>{assessment.orgSize}</TableCell>
              <TableCell>{assessment.orgSector}</TableCell>
              <TableCell>{new Date(assessment.lastSaved).toLocaleDateString()}</TableCell>
              <TableCell>{assessment.reportRequested ? 'Yes' : 'No'}</TableCell>
              <TableCell>
                  <Button onClick={() => viewFullReportAndPrompt(assessment._id)}>View Report & Edit Prompt</Button>
                {assessment.reportRequested && !assessment.reportGenerated && (
                  <Button onClick={() => handleGenerateReport(assessment._id)} className="ml-2">
                    Generate Report
                  </Button>
                )}
                <Button onClick={() => handlePDFDownload(assessment._id)} className="ml-2">
                 <FileDown className="mr-2 h-4 w-4" />
                  Download PDF
                </Button>
                  <Button onClick={() => handleDeleteAssessment(assessment)} variant="destructive" className="ml-2">
                    Delete
                  </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

  const renderActivityLogsTab = () => (
    <Card>
      <CardHeader>
        <CardTitle>Activity Logs</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>User</TableHead>
              <TableHead>Action</TableHead>
              <TableHead>Details</TableHead>
              <TableHead>Timestamp</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {activityLogs.map(log => (
              <TableRow key={log._id}>
                <TableCell>{log.user?.name || 'N/A'}</TableCell>
                <TableCell>{log.action || 'N/A'}</TableCell>
                <TableCell>{log.details || 'N/A'}</TableCell>
                <TableCell>{new Date(log.timestamp).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );

const renderReportAndPromptDialog = () => {
  console.log("renderReportAndPromptDialog called, showReportAndPromptDialog:", showReportAndPromptDialog);
  if (!selectedAssessment) {
    console.log("No selected assessment");
    return null;
  }

  return (
    <Dialog open={showReportAndPromptDialog} onOpenChange={setShowReportAndPromptDialog}>
      <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Assessment Report & Prompt</DialogTitle>
        </DialogHeader>
        <div className="mt-4">
          <h3 className="text-lg font-semibold">User: {selectedAssessment.user?.name || 'N/A'}</h3>
          <p>Organization Size: {selectedAssessment.orgSize}</p>
          <p>Organization Sector: {selectedAssessment.orgSector}</p>
          <Tabs defaultValue="report">
            <TabsList>
              <TabsTrigger value="report">Full Report</TabsTrigger>
              <TabsTrigger value="prompt">Edit Prompt</TabsTrigger>
            </TabsList>
            <TabsContent value="report">
              <h4 className="text-md font-semibold mt-4">Answers:</h4>
              {selectedAssessment.questions && selectedAssessment.answers && 
              selectedAssessment.questions.map(question => {
                const answer = selectedAssessment.answers[question.id];
                const note = selectedAssessment.assessmentNotes && selectedAssessment.assessmentNotes[question.id];
                return (
                  <div key={question.id} className="mt-2 border-b pb-2">
                    <p><strong>Question:</strong> {question.text[language] || question.text.en || 'Translation not available'}</p>
                    <p><strong>Answer:</strong> {answer || 'Not answered'}</p>
                    <p><strong>Assessment Note:</strong> {note || 'Not provided'}</p>
                  </div>
                );
              })}
            </TabsContent>
            <TabsContent value="prompt">
              <div className="mt-4">
                <Label htmlFor="prompt">Edit Prompt for ChatGPT</Label>
                <Textarea
                  id="prompt"
                  value={promptInput}
                  onChange={(e) => setPromptInput(e.target.value)}
                  rows={10}
                  className="mt-2"
                />
                <div className="flex items-center mt-2">
                  <Checkbox
                    id="adminOverride"
                    checked={isAdminOverride}
                    onCheckedChange={setIsAdminOverride}
                  />
                  <Label htmlFor="adminOverride" className="ml-2">
                    Admin Override
                  </Label>
                </div>
                <Button 
                  onClick={() => handleReleaseToAPI(selectedAssessment._id)} 
                  disabled={remainingApiCalls <= 0 && !isAdminOverride}
                  className="mt-2"
                >
                  Release to ChatGPT API ({remainingApiCalls} calls remaining)
                </Button>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </DialogContent>
    </Dialog>
  );
};

  if (!user || !user.isAdmin) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Admin Panel</h2>
      
      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      <Tabs defaultValue="users">
        <TabsList>
          <TabsTrigger value="users">Users</TabsTrigger>
          <TabsTrigger value="assessments">Assessments</TabsTrigger>
          <TabsTrigger value="activity">Activity Logs</TabsTrigger>
          <TabsTrigger value="workflow">Admin Workflow</TabsTrigger>
          <TabsTrigger value="threatIntel">Threat Intel</TabsTrigger>
        </TabsList>

        <TabsContent value="users">
          {renderUsersTab()}
        </TabsContent>

        <TabsContent value="assessments">
          {renderAssessmentsTab()}
        </TabsContent>

        <TabsContent value="activity">
          {renderActivityLogsTab()}
        </TabsContent>

        <TabsContent value="workflow">
          {renderWorkflowStep()}
        </TabsContent>

        <TabsContent value="threatIntel">
          <ThreatIntelTab />
        </TabsContent>
      </Tabs>

      <Dialog open={showDeleteConfirmation} onOpenChange={setShowDeleteConfirmation}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
          </DialogHeader>
          <p>Are you sure you want to delete this assessment? This action cannot be undone.</p>
          <DialogFooter>
            <Button onClick={() => setShowDeleteConfirmation(false)} variant="outline">Cancel</Button>
            <Button onClick={confirmDeleteAssessment} variant="destructive">Delete</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    {renderReportAndPromptDialog()}

    </div>
  );
};

export default AdminPanel;
