import React from 'react';
import { Card, CardContent } from './ui/card';

const ThreatIntelTab = () => {
  return (
    <div className="space-y-4">
      <Card className="bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <CardContent className="pt-6 text-center">
          <h2 className="text-2xl font-bold mb-4">Coming Soon</h2>
          <p className="text-gray-300 mb-6">
            Our advanced threat intelligence feature is currently in development.
            Soon, you'll have access to real-time cyber threat analysis tailored to your organization.
          </p>
          <div className="text-sm text-gray-400">
            "Innovation is saying 'no' to 1,000 things."
            <br />
            - Steve Jobs
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ThreatIntelTab; 