const translations = {
  en: {
    // General
    cyberAssessment: "Cyber Assessment",
    save: "Save",
    share: "Share",
    yes: "Yes",
    no: "No",
    next: "Next",
    previous: "Previous",
    finish: "Finish",

    // Organization Setup
    organizationSetup: "Organization Setup",
    pleaseProvide: "Please provide information about your organization",
    organizationSize: "Organization Size",
    selectSize: "Select size",
    small: "Small",
    medium: "Medium",
    large: "Large",
    organizationSector: "Organization Sector",
    selectSector: "Select sector",
    finance: "Finance",
    healthcare: "Healthcare",
    technology: "Technology",
    retail: "Retail",
    manufacturing: "Manufacturing",
    government: "Government",
    education: "Education",
    energy: "Energy",
    telecom: "Telecommunications",
    transportation: "Transportation",
    haveOsintData: "Do you have OSINT data to upload?",
    uploadOsintData: "Upload OSINT Data",
    startAssessment: "Start Assessment",
    questionNumber: "Question {current} of {total}",
    typeAnswerHere: "Type your answer here...",
    noQuestionsAvailable: "No questions available for the selected criteria.",
    analyzeResponses: "Analyze Responses",
    assignMaturityScore: "Assign Maturity Score",
    createActionPlan: "Create Action Plan",
    downloadPdf: "Download PDF Report",
    maturityScore: "Maturity Score: {score}",
    actionPlan: "Action Plan",
    organizationMaturity: "Organization Maturity",
    selectMaturity: "Select maturity level",
    basic: "Basic",
    intermediate: "Intermediate",
    advanced: "Advanced",

    // OSINT Upload
    uploadOsintDescription: "Upload your OSINT data Excel file",
    skipOsintUpload: "Skip OSINT Upload",

    // Assessment
    questionNumber: "Question {current} of {total}",
    typeAnswerHere: "Type your answer here...",
    downloadPdf: "Download PDF Report",
    analyzeResponses: "Analyze Responses",
    assignMaturityScore: "Assign Maturity Score",
    createActionPlan: "Create Action Plan",
    enterNumber: "Enter a number",
    optInAdvanced: "Would you like to answer additional advanced questions?",
    selectOption: "Select an option",
    organizationFramework: "Cybersecurity Framework",
    selectFramework: "Select Framework",
    NIST171: "NIST SP 800-171",
    ISO27001: "ISO/IEC 27001",
    CIS: "CIS Controls",
    BASICCOMPLIANCE: "Basic Compliance",
    PCIDSS: "PCI DSS",
    SOCII: "SOC 2 Type II",
    OTSECURITY: "OT Security",
    assessorHelperData: "Assessor Helper Data",
    assessmentGuidance: "Assessment Guidance",
    evidenceExamples: "Evidence Examples",
    scoringCriteria: "Scoring Criteria",

    // Recommendations
    recommendations: "Recommendations",
    recommendationsDescription: "Based on your responses, we recommend the following actions:",
    startNewAssessment: "Start New Assessment",
    downloadPdf: "Download PDF Report",

    // Analysis
    analyzeResponses: "Analyze Responses",
    assignMaturityScore: "Assign Maturity Score",
    createActionPlan: "Create Action Plan",
    analysisResults: "Analysis Results",
    requestFullReport: "Request Full Report and Action Plan",
    generatingReport: "Generating Report...",

    // Misc
    progressSaved: "Progress saved successfully!",
    collaborationLinkGenerated: "Collaboration Link Generated",
    shareToCollaborate: "Share this link to collaborate",
    disclaimer: 'CONFIDENTIAL: This document contains proprietary information and may not be shared without written permission from AstroByte, LLC.',
    viewHelperInformation: "View Helper Information",
    assessmentNotes: "Assessment Notes",
    enterAssessmentNotes: "Enter assessment notes here...",

    questions: {
      ADV001: "Can you identify the 3-5 most critical business processes crucial to your day-to-day operations and success?",
      ADV002: "Can you describe your overall technology infrastructure, including on-premise and cloud components?",
      // Add all other question translations here
    },

    // Organization Setup Tooltips
    orgSizeTooltip: `Select your organization's size:

• Small: Typically less than 50 employees
• Medium: Usually 50-250 employees
• Large: Generally more than 250 employees

This helps tailor the assessment to your organization's scale and resources.`,
  
    orgSectorTooltip: `Choose the sector that best describes your primary business activities:

• Finance: Banks, investment firms, insurance companies
• Healthcare: Hospitals, clinics, medical research
• Technology: Software development, IT services, electronics
• Retail: E-commerce, brick-and-mortar stores
• Manufacturing: Production of goods, factories
• Government: Public sector, local and national agencies
• Education: Schools, universities, e-learning platforms
• Energy: Oil & gas, renewable energy, utilities
• Telecom: Internet service providers, phone companies
• Transportation: Airlines, shipping, public transit

This selection helps customize the assessment to address industry-specific cybersecurity challenges and regulatory requirements.`,
  
    maturityTooltip: `Assess your current cybersecurity maturity level:

• Basic: Minimal cybersecurity practices in place
• Intermediate: Standard security measures implemented
• Advanced: Comprehensive security program with continuous improvement

This helps adjust the assessment depth and recommendations.`,
  
    frameworkTooltip: `Select the cybersecurity framework most relevant to your organization:

• NIST SP 800-171: For handling Controlled Unclassified Information
• ISO/IEC 27001: International standard for information security management
• CIS Controls: Prioritized set of actions to protect from cyber attacks
• Basic Compliance: Fundamental practices for organizations starting their security journey
• PCI DSS: Payment Card Industry Data Security Standard for protecting payment card data
• SOC 2 Type II: Service Organization Control framework for service providers
• OT Security: Operational Technology security for industrial control systems

Choosing a framework helps align the assessment with specific standards and best practices.`,
  
    osintTooltip: `Open Source Intelligence (OSINT) includes publicly available information about your organization. 

Uploading OSINT data can provide:
• A more comprehensive assessment of your external cybersecurity posture
• Insights into potential vulnerabilities visible to threat actors
• A broader perspective on your organization's digital footprint

This additional context enhances the accuracy and relevance of the assessment results.`,

    // Business Helper Data Fields
    purpose: "Purpose",
    assessmentInsights: "Assessment Insights",
    influenceOnAssessment: "Influence on Assessment",

    // Add section headers
    businessContextSection: "Business Context",
    generalAssessmentSection: "General Assessment",
  },
  es: {
    // General
    cyberAssessment: "Evaluación de Ciberseguridad",
    save: "Guardar",
    share: "Compartir",
    yes: "Sí",
    no: "No",
    next: "Siguiente",
    previous: "Anterior",
    finish: "Finalizar",

    // Organization Setup
    organizationSetup: "Configuración de la Organización",
    pleaseProvide: "Por favor, proporcione información sobre su organización",
    organizationSize: "Tamaño de la Organización",
    selectSize: "Seleccionar tamaño",
    small: "Pequeña",
    medium: "Mediana",
    large: "Grande",
    organizationSector: "Sector de la Organización",
    selectSector: "Seleccionar sector",
    finance: "Finanzas",
    healthcare: "Salud",
    technology: "Tecnología",
    retail: "Comercio Minorista",
    manufacturing: "Manufactura",
    government: "Gobierno",
    education: "Educación",
    energy: "Energía",
    telecom: "Telecomunicaciones",
    transportation: "Transporte",
    haveOsintData: "¿Tiene datos OSINT para cargar?",
    uploadOsintData: "Cargar Datos OSINT",
    startAssessment: "Iniciar Evaluación",
    questionNumber: "Pregunta {current} de {total}",
    typeAnswerHere: "Escriba su respuesta aquí...",
    noQuestionsAvailable: "No hay preguntas disponibles para los criterios seleccionados.",
    analyzeResponses: "Analizar Respuestas",
    assignMaturityScore: "Asignar Puntuación de Madurez",
    createActionPlan: "Crear Plan de Acción",
    downloadPdf: "Descargar Informe PDF",
    maturityScore: "Puntuación de Madurez: {score}",
    actionPlan: "Plan de Acción",
    organizationMaturity: "Madurez de la Organización",
    selectMaturity: "Seleccione el nivel de madurez",
    basic: "Básico",
    intermediate: "Intermedio",
    advanced: "Avanzado",

    // OSINT Upload
    uploadOsintDescription: "Cargue su archivo Excel de datos OSINT",
    skipOsintUpload: "Omitir Carga OSINT",

    // Assessment
    questionNumber: "Pregunta {current} de {total}",
    typeAnswerHere: "Escriba su respuesta aquí...",
    downloadPdf: "Descargar Informe PDF",
    analyzeResponses: "Analizar Respuestas",
    assignMaturityScore: "Asignar Puntuación de Madurez",
    createActionPlan: "Crear Plan de Acción",
    enterNumber: "Ingrese un número",
    optInAdvanced: "¿Le gustaría responder preguntas adicionales avanzadas?",
    selectOption: "Seleccione una opción",
    organizationFramework: "Marco de Ciberseguridad",
    selectFramework: "Seleccionar un marco",
    NIST171: "NIST SP 800-171",
    ISO27001: "ISO/IEC 27001",
    CIS: "Controles CIS",
    BASICCOMPLIANCE: "Cumplimiento Básico",
    PCIDSS: "PCI DSS",
    SOCII: "SOC 2 Tipo II",
    OTSECURITY: "Seguridad OT",
    assessorHelperData: "Datos de Ayuda para el Evaluador",
    assessmentGuidance: "Guía de Evaluación",
    evidenceExamples: "Ejemplos de Evidencia",
    scoringCriteria: "Criterios de Puntuación",

    // Recommendations
    recommendations: "Recomendaciones",
    recommendationsDescription: "Basado en sus respuestas, recomendamos las siguientes acciones:",
    startNewAssessment: "Iniciar Nueva Evaluación",
    downloadPdf: "Descargar Informe PDF",

    // Analysis
    analyzeResponses: "Analizar Respuestas",
    assignMaturityScore: "Asignar Puntuación de Madurez",
    createActionPlan: "Crear Plan de Acción",
    analysisResults: "Resultados del Análisis",
    requestFullReport: "Solicitar Informe Completo y Plan de Acción",
    generatingReport: "Generando Informe...",

    // Misc
    progressSaved: "¡Progreso guardado con éxito!",
    collaborationLinkGenerated: "Enlace de Colaboración Generado",
    shareToCollaborate: "Comparta este enlace para colaborar",
    disclaimer: 'CONFIDENCIAL: Este documento contiene información propietaria y no puede ser compartido sin permiso escrito de AstroByte, LLC.',
    viewHelperInformation: "Ver Información de Ayuda",
    assessmentNotes: "Notas de Evaluación",
    enterAssessmentNotes: "Ingrese notas de evaluación aquí...",

    questions: {
      ADV001: "¿Puede identificar los 3-5 procesos de negocio más críticos esenciales para sus operaciones diarias y éxito?",
      ADV002: "¿Puede describir su infraestructura tecnológica general, incluidos los componentes locales y en la nube?",
      // Add all other question translations here
    },

    // Organization Setup Tooltips
    orgSizeTooltip: `Seleccione el tamaño de su organización:

• Pequeña: Típicamente menos de 50 empleados
• Mediana: Usualmente entre 50-250 empleados
• Grande: Generalmente más de 250 empleados

Esto ayuda a adaptar la evaluación a la escala y recursos de su organización.`,
  
    orgSectorTooltip: `Elija el sector que mejor describe sus actividades comerciales principales:

• Finanzas: Bancos, firmas de inversión, compañías de seguros
• Salud: Hospitales, clínicas, investigación médica
• Tecnología: Desarrollo de software, servicios de TI, electrónica
• Comercio minorista: Comercio electrónico, tiendas físicas
• Manufactura: Producción de bienes, fábricas
• Gobierno: Sector público, agencias locales y nacionales
• Educación: Escuelas, universidades, plataformas de aprendizaje en línea
• Energía: Petróleo y gas, energía renovable, servicios públicos
• Telecomunicaciones: Proveedores de servicios de Internet, compañías telefónicas
• Transporte: Aerolíneas, envíos, transporte público

Esta selección ayuda a personalizar la evaluación para abordar los desafíos de ciberseguridad y requisitos regulatorios específicos de la industria.`,
  
    maturityTooltip: `Evalúe su nivel actual de madurez en ciberseguridad:

• Básico: Prácticas mínimas de ciberseguridad implementadas
• Intermedio: Medidas de seguridad estándar implementadas
• Avanzado: Programa de seguridad integral con mejora continua

Esto ayuda a ajustar la profundidad de la evaluación y las recomendaciones.`,
  
    frameworkTooltip: `Seleccione el marco de ciberseguridad más relevante para su organización:

• NIST SP 800-171: Para el manejo de Información Controlada No Clasificada
• ISO/IEC 27001: Estándar internacional para la gestión de seguridad de la información
• Controles CIS: Conjunto priorizado de acciones para proteger contra ataques cibernéticos
• Cumplimiento Básico: Prácticas fundamentales para organizaciones que inician su viaje de seguridad
• PCI DSS: Estándar de Seguridad de Datos para la Industria de Tarjetas de Pago
• SOC 2 Tipo II: Marco de Control para Organizaciones de Servicios
• Seguridad OT: Seguridad de Tecnología Operacional para sistemas de control industrial

La elección de un marco ayuda a alinear la evaluación con estándares específicos y mejores prácticas.`,
  
    osintTooltip: `La Inteligencia de Fuentes Abiertas (OSINT) incluye información públicamente disponible sobre su organización.

Cargar datos OSINT puede proporcionar:
• Una evaluación más completa de su postura de ciberseguridad externa
• Información sobre vulnerabilidades potenciales visibles para los actores de amenazas
• Una perspectiva más amplia de la huella digital de su organización

Este contexto adicional mejora la precisión y relevancia de los resultados de la evaluación.`,

    // Business Helper Data Fields
    purpose: "Propósito",
    assessmentInsights: "Perspectivas de Evaluación",
    influenceOnAssessment: "Influencia en la Evaluación",

    // Add section headers
    businessContextSection: "Contexto Empresarial",
    generalAssessmentSection: "Evaluación General",
  }
};

export default translations;
