import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: { padding: 30 },
  section: { margin: 10, padding: 10 },
  header: { fontSize: 18, marginBottom: 20 },
  content: { fontSize: 12, marginBottom: 10 },
  title: { fontSize: 24, marginBottom: 20 },
  subtitle: { fontSize: 18, marginBottom: 10 },
  text: { fontSize: 12 },
  questionSection: { marginBottom: 10 },
  tableRow: { flexDirection: 'row', marginBottom: 10 },
  tableCol: { width: '50%', padding: 10 },
  tableCell: { fontSize: 12 },
  disclaimer: { fontSize: 12, marginBottom: 10 },
});

const AssessmentPDF = ({ 
  orgSize, 
  orgSector, 
  answers, 
  questions,
  language,
  osintData,
  analysisResult,
  maturityScore,
  selectedFramework,
  actionPlan,
  assessmentNotes
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>{language === 'en' ? 'Cyber Assessment Report' : 'Informe de Evaluación de Ciberseguridad'}</Text>
        
        <Text style={styles.subtitle}>{language === 'en' ? 'Organization Details:' : 'Detalles de la Organización:'}</Text>
        <Text style={styles.text}>{language === 'en' ? 'Size: ' : 'Tamaño: '}{orgSize}</Text>
        <Text style={styles.text}>{language === 'en' ? 'Sector: ' : 'Sector: '}{orgSector}</Text>
        
        <Text style={styles.subtitle}>{language === 'en' ? 'Assessment Responses:' : 'Respuestas de la Evaluación:'}</Text>
        {questions.map((question, index) => (
          <View key={index} style={styles.questionSection}>
            <Text style={styles.text}>
              {language === 'en' ? 'Question ' : 'Pregunta '}{index + 1}: {question.text[language]}
            </Text>
            <Text style={styles.text}>{language === 'en' ? 'Answer: ' : 'Respuesta: '}{answers[question.id] || (language === 'en' ? 'Not answered' : 'Sin respuesta')}</Text>
          </View>
        ))}
        
        {osintData && (
          <>
            <Text style={styles.subtitle}>{language === 'en' ? 'OSINT Data:' : 'Datos OSINT:'}</Text>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{language === 'en' ? 'Key' : 'Clave'}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{language === 'en' ? 'Value' : 'Valor'}</Text>
              </View>
            </View>
            {Object.entries(osintData).map(([key, value], index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{key}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{value}</Text>
                </View>
              </View>
            ))}
          </>
        )}

        {Object.entries(assessmentNotes).map(([questionId, note]) => (
          <View key={questionId} style={styles.questionSection}>
            <Text style={styles.text}>
              {language === 'en' ? 'Assessment Note for Question ' : 'Nota de Evaluación para la Pregunta '}{questionId}:
            </Text>
            <Text style={styles.text}>{note}</Text>
          </View>
        ))}
        
        {analysisResult && (
          <>
            <Text style={styles.subtitle}>{language === 'en' ? 'Analysis Results:' : 'Resultados del Análisis:'}</Text>
            <Text style={styles.text}>{analysisResult}</Text>
          </>
        )}
        
        {maturityScore && (
          <>
            <Text style={styles.subtitle}>{language === 'en' ? 'Maturity Score:' : 'Puntuación de Madurez:'}</Text>
            <Text style={styles.text}>{language === 'en' ? `Framework: ${selectedFramework}` : `Marco: ${selectedFramework}`}</Text>
            <Text style={styles.text}>{language === 'en' ? `Score: ${maturityScore}` : `Puntuación: ${maturityScore}`}</Text>
          </>
        )}
        
        {actionPlan && (
          <>
            <Text style={styles.subtitle}>{language === 'en' ? 'Action Plan:' : 'Plan de Acción:'}</Text>
            <Text style={styles.text}>{actionPlan}</Text>
          </>
        )}

        <Text style={styles.disclaimer}>
          {language === 'en' 
            ? 'CONFIDENTIAL: This document contains proprietary information and may not be shared without written permission from AstroByte, LLC.' 
            : 'CONFIDENCIAL: Este documento contiene información propietaria y no puede ser compartido sin permiso escrito de AstroByte, LLC.'}
        </Text>
      </View>
    </Page>
  </Document>
);

export default AssessmentPDF;
